
import React from "react";

const Search = () => {
  return (
    <div className="flex items-center">
      <input
        maxLength={100}
        placeholder="Search"
        className="border outline-none rounded-r-none border-[#3AC170] py-2 px-4 rounded-md w-[222px]"
        type="text"
      />
      <button className="bg-[#3AC170] h-[43px] p-4 flex items-center rounded-r-md justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
        >
          <path
            d="M12.9864 12.6966L16.2023 15.9055C16.3688 16.072 16.5424 16.2336 16.7009 16.4101C16.79 16.4925 16.8612 16.5923 16.9104 16.7033C16.9595 16.8143 16.9854 16.9342 16.9866 17.0555C16.9878 17.1769 16.9642 17.2972 16.9172 17.4091C16.8702 17.5211 16.8009 17.6222 16.7134 17.7064C16.626 17.7906 16.5223 17.856 16.4087 17.8987C16.295 17.9414 16.1739 17.9604 16.0527 17.9546C15.9314 17.9488 15.8126 17.9184 15.7036 17.865C15.5946 17.8117 15.4976 17.7367 15.4185 17.6446C14.3346 16.5806 13.2457 15.5196 12.2006 14.4177C12.1307 14.3135 12.0403 14.2247 11.9349 14.1567C11.8295 14.0887 11.7114 14.043 11.5877 14.0223C11.464 14.0015 11.3374 14.0063 11.2156 14.0362C11.0938 14.0662 10.9794 14.1206 10.8794 14.1963C9.8667 14.7093 8.75066 14.9853 7.61563 15.0035C6.4806 15.0217 5.35627 14.7816 4.3277 14.3013C3.29912 13.821 2.39323 13.1132 1.67847 12.2313C0.963715 11.3494 0.458829 10.3165 0.201988 9.21077C-0.0548524 8.10503 -0.0569222 6.95537 0.195932 5.84871C0.448787 4.74205 0.949947 3.70739 1.66152 2.82292C2.37309 1.93845 3.27645 1.22734 4.30329 0.743373C5.33013 0.259402 6.45354 0.0152284 7.58863 0.029325C8.96201 0.0493347 10.3036 0.445574 11.4675 1.17492C12.6314 1.90427 13.573 2.93879 14.1899 4.16599C14.8068 5.39319 15.0753 6.76606 14.9663 8.13525C14.8573 9.50445 14.375 10.8176 13.5717 11.9317C13.4002 12.1681 13.2168 12.3904 12.9864 12.6966ZM7.46499 13.2161C8.59133 13.2224 9.69422 12.8943 10.634 12.2734C11.5738 11.6525 12.3081 10.7667 12.7442 9.72817C13.1802 8.68961 13.2982 7.54502 13.0833 6.43936C12.8683 5.33369 12.3301 4.31666 11.5368 3.51707C10.7435 2.71747 9.7308 2.17126 8.62687 1.94761C7.52293 1.72397 6.37744 1.83294 5.33547 2.26073C4.29351 2.68852 3.40192 3.4159 2.77361 4.35074C2.14531 5.28558 1.80856 6.38584 1.80598 7.51219C1.80006 8.25912 1.94204 8.99982 2.22378 9.69159C2.50552 10.3834 2.92144 11.0125 3.44752 11.5428C3.9736 12.073 4.59945 12.4939 5.28897 12.7811C5.9785 13.0683 6.71804 13.2161 7.46499 13.2161Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  );
};

export default Search;
